.main {
  margin-top: 5em;
}

.center {
  justify-content: center;
  padding: 10px;
}

.ui.search input {
  width: 100%;
  border-radius: 0 !important;
}

.item {
  padding: 15px 0px !important;
}

i.icon {
  float: right;
  font-size: 20px;
  cursor: pointer;
}

